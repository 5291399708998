<template>
  <div>
    <city-picker-weather />
    <b-card>
      {{ $i18n.availableLocales }}
      <b-button block @click="$i18n.locale = 'en'"> EN </b-button>
      <b-button block @click="$i18n.locale = 'de'"> DE </b-button>
    </b-card>
    <b-card :key="refreshKey">
      <div class="truncate">
        <h2 class="mb-25 font-weight-bolder">
          {{ $t("organisations") }}
        </h2>
      </div>
      <b-card-body>
        <b-row v-for="organization in organizations" :key="organization.id">
          <div :key="organization.id" class="mb-2">
            {{ organization }}
          </div>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card>
      <div class="truncate">
        <h2 class="mb-25 font-weight-bolder">
          {{ $t("suborganizations") }}
        </h2>
      </div>
      <b-card-body>
        <b-row v-for="suborganization in suborganizations" :key="suborganization.id">
          <div :key="suborganization.id" class="mb-2">
            {{ suborganization }}
          </div>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card>
      <div class="truncate">
        <h2 class="mb-25 font-weight-bolder">
          {{ $t("clusters") }}
        </h2>
      </div>
      <b-card-body>
        <b-row v-for="cluster in clusters" :key="cluster.id">
          <div :key="cluster.id" class="mb-2">
            {{ cluster }}
          </div>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card>
      <div class="truncate">
        <h2 class="mb-25 font-weight-bolder">LocalGroups</h2>
      </div>
      <b-card-body>
        <b-row v-for="localGroup in localGroups" :key="localGroup.id">
          <div :key="localGroup.id" class="mb-2">
            {{ localGroup }}
          </div>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card>
      <div class="truncate">
        <h2 class="mb-25 font-weight-bolder">
          {{ $t("emmas") }}
        </h2>
      </div>
      <b-card-body class="d-flex justify-content-between align-items-center">
        {{ emmas }}
      </b-card-body>
    </b-card>
    <b-card>
      <div class="truncate">
        <h2 class="mb-25 font-weight-bolder">
          {{ $t("users") }}
        </h2>
      </div>
      <b-card-body class="d-flex justify-content-between align-items-center">
        {{ userData }}
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardBody, BRow, BButton } from "bootstrap-vue"
import CityPickerWeather from "@/components/emma/weather/CityPickerWeather.vue"

export default {
  name: "ApiTesting",
  components: {
    BCard,
    BCardBody,
    BRow,
    BButton,
    CityPickerWeather,
  },
  data() {
    return {
      refreshKey: 0,
    }
  },
  computed: {
    organizations() {
      return this.$store.state.organizations.organizations
    },
    suborganizations() {
      return this.$store.state.suborganizations.suborganizations
    },
    clusters() {
      return this.$store.state.clusters.clusters
    },
    localGroups() {
      return this.$store.state.localGroups.localGroups
    },
    emmas() {
      return this.$store.state.emmas.emmas
    },
    userData() {
      return localStorage.getItem("userData")
    },
  },
  async mounted() {
    this.refreshKey += 1
  },
  methods: {
    async testUserOrganizationsUpdate() {
      await this.$store.dispatch("users/updateUserOrganizations", {
        userId: 1,
        organizationIDs: [1, 2, 3],
      })
    },
    async getUsers() {
      const users = await this.$http.get("/users")
      console.log(users.data)
    },
  },
}
</script>

<style scoped></style>
